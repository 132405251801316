import React from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import UpComingProjects from './coming_projects'
const ProjectsWrapper = () => {
  return (
    <>
    <Header/>
    <UpComingProjects />
    <Footer />
    </>
  )
}

export default ProjectsWrapper