import React from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Product from "./product/Product";

const ProductWrap = () => {
	return (
		<>
			<Header />
			<Product />
			<Footer />
		</>
	);
};

export default ProductWrap;
