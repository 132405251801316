import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Footer.css";

const Footer = () => {
	return (
		<>
			<div id='footer'>
				<div className='footerSection'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-4'>
								<div className='contactUs'>
									<h3>Contact Us</h3>
									<ul>
										<li>
											<i className='fas fa-map-marker-alt'></i>
											<span>Madhyapur Thimi-7, Bhaktapur, Nepal</span>
										</li>
										<li>
											<i className='fas fa-phone-alt'></i>
											<span>Landline: 01-6630135</span>
										</li>
										<li>
											<i className='fas fa-headset'></i>
											<span>Hotline: +9779860497192</span>
										</li>
										<li>
											<i className='fas fa-envelope'></i>
											<span>gg.foodproduction@gmail.com</span>
										</li>
									</ul>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='quickLinks'>
									<h3>Quick Links</h3>
									<ul>
										<li>
											<HashLink
												to='/#home'
												className='active link'
												scroll={(el) =>
													el.scrollIntoView({
														behavior: "smooth",
														block: "center",
													})
												}
											>
												Home
											</HashLink>
										</li>
										<li>
											<Link to='/about' className='link'>
												About us
											</Link>
										</li>
										<li>
											{" "}
											<HashLink
												to='/#our_product'
												className='link'
												scroll={(el) =>
													el.scrollIntoView({
														behavior: "smooth",
														block: "start",
													})
												}
											>
												Our Product
											</HashLink>
										</li>
										<li>
											<Link to='/projects' className='comingSoon'>
												Upcoming Services
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='socialLinks'>
									<h3>Social Links</h3>
									<ul className='socialLinks'>
										<li>
											<a
												href='https://www.facebook.com/341816533903090/posts/689079222510151/?flite=scwspnss'
												target='_blank'
												rel='noreferrer noopener'
											>
												<i className='fab fa-facebook'></i>
												<span>Facebook</span>
											</a>
										</li>
										<li>
											<a
												href='https://www.instagram.com/gg.foodproduction/'
												target='_blank'
												rel='noreferrer noopener'
											>
												<>
													<i className='fab fa-instagram'></i>
													<span>Instagram</span>
												</>
											</a>
										</li>
										<li>
											<a
												href='https://twitter.com/GrabPvt?t=2w5-aQ3P7ZdFP9jFD9F8AA&s=08'
												target='_blank'
												rel='noreferrer noopener'
											>
												<>
													<i className='fab fa-twitter'></i>
													<span>Twitter</span>
												</>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='footerBottom'>
					<p>
						COPYRIGHT{" "}
						<span>
							<i className='fas fa-circle'></i>
						</span>{" "}
						2022 GRAB & GO ON THE GO
					</p>
				</div>
			</div>
		</>
	);
};

export default Footer;
