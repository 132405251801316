import React from "react";
import './ProductCard.css'
const Card = (props) => {
  return (
    <>
      <div className="product-card "  >
        <div className="image-wrapper">
        <img className="product-image" src={props.image} alt="product" />
        </div>
        <div className="detail">
          <h3 className="title">{props.title}</h3>
        </div>
      </div>
      
    </>
  );
};

export default Card;
