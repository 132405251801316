const List = [
  {
    id: 1,
    title:"1.Choose Your Food",
    image: "../images/mobile.png",
    detail:
    "You can place order by call or messsage on our social media.",
  },
  {
    id: 2,
    title:"2.We Deliver To Your DoorStep",
    image: "../images/vehicle.png",
    detail:
    "We deliver your order as quick as possible.",
  },
  {
    id: 3,
    title:"3.Heat, Eat and Enjoy",
    image: "../images/coffee.png",
    detail:
    "Now, heat and enjoy the taste.",
  }
  ];
  
  export default List;
  