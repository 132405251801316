import React from "react";
import "./coming_projects.css";
import upcoming_products from "./coming_products";
import Card from "../product/ProductCard";
import { Foodcast, Outlet } from "./ProjectLayout";
const UpComingProjects = () => {
	return (
		<div className='coming-soon container'>
			<div className='top'>
				<h3 className='heading'> Upcoming Projects</h3>
				<p className='description'>Our upcoming projects</p>
				<div className='wrapper'>
					<div className='layout'>
						<Foodcast />
					</div>
					<div className='layout'>
						<Outlet />
					</div>
				</div>
			</div>

			<div className='bottom'>
				<div id='our_product' style={{ padding: "20px 0px" }}>
					<div className='head_wrapper'>
						<h4 className='heading'>Upcoming Products</h4>
						<p className='description'>Our new upcoming products</p>
					</div>
					<div className='container'>
						<div className='row'>
							{upcoming_products.map((product) => {
								return (
									<div className='col-md-3 card-box'>
										<Card
											key={product.id}
											image={product.image}
											title={product.title}
										/>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpComingProjects;
