import React from 'react'
import SingleAbout from './SingleAbout'
import Footer from '../footer/Footer'
import Header from '../header/Header'

const AboutWrapper = () => {
  return (
    <>
    <Header/>
    <SingleAbout/>
    <Footer/>
    </>
  )
}

export default AboutWrapper;