import React from "react";
import ProductList from "./ProductList";
import "./Product.css";
import Card from "./ProductCard";

const Product = () => {
  return (
    <>
      <div id="our_product" style={{ padding: "25px 0px" }}>
        <div className="head_wrapper">
          <h4 className="heading">Our Products</h4>
          <p className="description">we serve the following momo's.,</p>
        </div>
        <div className="container">
          <div className="row">
            {ProductList.map((product) => {
              return (
                <div className=" col-lg-3 col-md-6 col-sm-6 card-box">
                  <Card
                    key={product.id}
                    image={product.image}
                    title={product.title}
                    price={product.price}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
