import React, { useRef, useState } from "react";
import "./GetInTouch.css";

const Form = () => {
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const messageRef = useRef();
  const onSubmit = (e) => {
    e.preventDefault();
    if (!message || message === "") {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    } else {
      console.log(message);
      const mobileNumber = +85570224764;
      let url = `https://web.whatsapp.com/send?phone=${mobileNumber}`;
      url += `&text=${encodeURI(message)}&app_absent=0`;
      window.open(url);
      setMessage(null);
      messageRef.current.value = "";
    }
  };
  return (
    <>
      <div className="formColumn">
        <div className="formWrap">
          <div className="formTopSection">
            <h3>Let's Talk</h3>
            <p>
              If you have any queries, Please send us a message in whatsApp. We
              will reach you soon.
            </p>
          </div>
          <form action="form">
            <textarea
              ref={messageRef}
              name=""
              rows="5"
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Your Messages"
              type="text"
            ></textarea>
            {showError && (
              <span style={{ color: "red", fontSize:"1rem" }}> Please enter a message </span>
            )}
            <button type="submit" onClick={onSubmit}>
              submit now
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Form;
