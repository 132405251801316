import React from "react";
import List from "./List";
import Card from "./Card";
import "./HowITWork.css"
const HowItWork = () => {
  return (
    <>
      <div className="how_it_works_wrap">
        <div
          className="container"
          id="how_it_works"

        >
          <div className="row">
            <div className="container">
              <div className="wrapper">
                <h4 className="heading">How we Works</h4>
                <p className="description">
                  Choose products, Place Order and Enjoy the taste.
                </p>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {List.map((product) => {
                  return (
                    <Card
                      key={product.id}
                      image={product.image}
                      title={product.title}
                      detail={product.detail}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWork;
