import React from "react";
import Product from "../product/Product";
import HowItWork from "../howitworks/HowItWork";
import Header from "../header/Header";
import Banner from "../banner/Banner";
import Footer from "../footer/Footer";
import GetInTouch from "../getintouch/GetInTouch";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import About from "../about/About";
const Home = () => {
  return (
    <>
    <div id="home">
      <Header />
      <Banner />
      <About/>
      <HowItWork />
      <Product />
      <GetInTouch />
      <Footer />
      </div>
    </>
  );
};

export default Home;
