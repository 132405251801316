import React from 'react'
import './About.css'


const About = (props) => {
  return (
      <>
        <div className="main_abot_wrap">
            <div className='container' id='about'>
                <div className="about">
                        <div className="row">
                            <div className='col-md-6'>
                            <img className='about_img' src="../images/about1.png" alt="" />
                            </div>
                            <div className='col-md-6'>
                                <div className="content">
                                    <h2>About Grab & Go</h2>
                                    <p>Grab & Go food packaging is a subset of the global food processing industry and this industry is made up of companies that are engaged in the processing and packaging of food.
            Our food packaging plant is going to be a standard commercial company hence will make use of packaging materials that provide protection, tampering resistance, and special physical, chemical, or biological needs.
            Our Vision is to become a household brand and to have our products in groceries stores, and supermarkets not just across the Nepal but also on the global stage. </p>
                                </div>
                            </div>
                        </div>
                        <div className="mid row">
                            <div className='mid_content col-md-6'>
                                <div className="content">
                                    <h2>We deliver our products the way you expect</h2>
                                    <p>Once, you place a order, We start a delivery process for you. Our delivery will be as quick as possible. </p>
                                </div>
                            </div>
                            <div className='mid_about_img col-md-6'>
                            <img className='about_img' src="../images/about2.png" alt="" />
                            </div>
                        </div>
            
                        <div className="row">
                            <div className='col-md-6'>
                                <img className='about_img' src="../images/about3.png" alt="" />
                            </div>
                            <div className='col-md-6'>
                                <div className="content">
                                    <h2>Expert in ambient, fresh and frozen food delivery</h2>
                                    <p>Periodic revision of the quality policy and integration with possibly needed target-oriented adjustments of the activities. We have implemented our own System for the Quality Control with the involvement of all functions and it constantly checks that the prescribed rules are regularly applied to all stages, from the product development to the sales and related services .
                                    </p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
      </>
  )
}

export default About;