const upcoming_products = [
	{
		id: 1,
		title: "Pitta Bread",
		image: "../images/pitta-bread.png",
	},
	{
		id: 2,
		title: "Sukha Roti",
		image: "../images/sukha-roti.jpeg",
	},
	{
		id: 3,
		title: "Kuboos (Arabic Bread)",
		image: "../images/kuboos.jpeg",
	},
	{
		id: 4,
		title: "Homemade Pickel",
		image: "../images/pickle.jpeg",
	},
];

export default upcoming_products;
