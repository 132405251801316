import React from "react";
import Footer from "./footer/Footer";
import GetInTouch from "./getintouch/GetInTouch";
import Header from "./header/Header";

const ContactWrap = () => {
	return (
		<>
			<Header />
			<GetInTouch />
			<Footer />
		</>
	);
};

export default ContactWrap;
