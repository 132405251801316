import React from 'react'
import "./projectlayout.css"
export const  Foodcast = () => {
  return (
    <div className="main">
          <div className="row-md-6 ">
            <img className="project_image" src="../images/single1.png" alt="" />
          </div>
          <div className="row-md-6">
            <div className="project_detail">
              <h2>Foodcast</h2>
              <p>
              Our food outlet is a small vechicles shop that serves food within a valley.
               We have a different types of foods. We move place to place to serve our  hot & delicious food.
              </p>
            </div>
          </div>
        </div>
  )
}
 export const Outlet = () => {
  return (
    <div className="main">
          <div className="row-md-6 ">
            <img className="project_image" src="../images/outlet.jpeg" alt="" />
          </div>
          <div className="row-md-6">
            <div className="project_detail">
              <h2>Outlet</h2>
              <p>
               Our food outlet is a small vechicles shop that serves food within a valley.
               We have a different types of foods. We move place to place to serve our  hot & delicious food.
              </p>
            </div>
          </div>
        </div>
  )
}
