import React from "react";
import Slider from "react-slick";
import "./Banner.css"

const Banner = () => {

  const settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    speed: 5,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  return (
   <>
   <div className="banner">
      <div className="banner_slider">
      <Slider {...settings}>
         
          <div className="banner_img">
            <img src="images/banner1.jpeg" alt="" />
          </div>

          <div className="banner_img">
            <img src="images/banner1.jpeg" alt="" />
          </div>

        </Slider>
      </div>
   </div>
   
   </>
  );
};

export default Banner;
