import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
	const close = useRef(null);

	const handleClose = () => {
		close.current.click();
	};
	return (
		<>
			<div className='header' id='header'>
				<div className='top_nav'>
					<div className='container'>
						<ul>
							<li>
								<i className='fas fa-envelope'></i>
								<span>gg.foodproduction@gmail.com</span>
							</li>
							<li>
								<i className='fas fa-phone-alt'></i>
								<span>+9779860497192</span>
							</li>
							<li>
								<HashLink
									to='https://www.facebook.com/341816533903090/posts/689079222510151/?flite=scwspnss'
									scroll={(el) =>
										el.scrollIntoView({ behavior: "smooth", block: "center" })
									}
									target='_blank'
									rel='noreferrer noopener'
								>
									<i className='fab fa-facebook'></i>
								</HashLink>
							</li>

							<li>
								<HashLink
									to='https://www.instagram.com/gg.foodproduction/'
									scroll={(el) =>
										el.scrollIntoView({ behavior: "smooth", block: "center" })
									}
									target='_blank'
									rel='noreferrer noopener'
								>
									<i className='fab fa-instagram'></i>
								</HashLink>
							</li>
							<li>
								<HashLink
									to='https://twitter.com/GrabPvt?t=2w5-aQ3P7ZdFP9jFD9F8AA&s=08'
									scroll={(el) =>
										el.scrollIntoView({ behavior: "smooth", block: "center" })
									}
									target='_blank'
									rel='noreferrer noopener'
								>
									<i className='fab fa-twitter'></i>
								</HashLink>
							</li>
						</ul>
					</div>
				</div>
				<div className='main_nav'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-6 col-md-12 logo_wrap'>
								<div className='logo'>
									<a href='/#home'>
										<img src='images/logo.png' alt='' />
									</a>
								</div>
								<div className='hamburger'>
									<a
										className='btn btn-primary'
										data-bs-toggle='offcanvas'
										href='#offcanvasExample'
										role='button'
										aria-controls='offcanvasExample'
									>
										<i className='fas fa-bars'></i>
									</a>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='nav_wrapper'>
									<ul className='nav_bar'>
										<li>
											<HashLink
												to='/#home'
												className='active link'
												onClick={{}}
												scroll={(el) =>
													el.scrollIntoView({
														behavior: "smooth",
														block: "center",
													})
												}
											>
												HOME
											</HashLink>
										</li>
										<li>
											<Link to='/products' className='link'>
												OUR PRODUCT
											</Link>
										</li>
										<li>
											<Link to='/about' className='link'>
												ABOUT US
											</Link>
										</li>

										<li>
											<Link to='/contact_us' className='link'>
												CONTACT US
											</Link>
										</li>
										<li>
											<Link to='/projects' className='comingSoon'>
												UPCOMING SERVICES
											</Link>
										</li>
									</ul>
								</div>

								<div className='canvas_wrap'>
									<div
										className='offcanvas offcanvas-start'
										tabIndex='-1'
										id='offcanvasExample'
										aria-labelledby='offcanvasExampleLabel'
										data-bs-scroll='true'
									>
										<button
											type='button'
											class='btn-close text-reset'
											data-bs-dismiss='offcanvas'
											aria-label='Close'
											ref={close}
											style={{ display: "none" }}
										></button>
										<h2>MENU</h2>
										<ul className='nav_bar'>
											<li>
												<HashLink
													to='/#home'
													className='active link'
													onClick={{}}
													scroll={(el) =>
														el.scrollIntoView({
															behavior: "smooth",
															block: "center",
														})
													}
												>
													HOME
												</HashLink>
											</li>
											<li>
												<Link to='/products' className='link'>
													OUR PRODUCT
												</Link>
											</li>
											<li>
												<Link to='/about' className='link'>
													ABOUT US
												</Link>
											</li>

											<li>
												<Link to='/contact_us' className='link'>
													CONTACT US
												</Link>
											</li>
											<li>
												<Link to='/projects' className='comingSoon'>
													UPCOMING SERVICES
												</Link>
											</li>
										</ul>
										<div
											className='offcanvas-backdrop fade show'
											style={{ opacity: "0", position: "fixed", zIndex: "-1" }}
											onClick={handleClose}
										></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
