import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutWrapper from "./components/about_single/AboutWrapper";
import ProjectsWrapper from "./components/upcoming_projects/ProjectsWrapper";
import ScrollToTop from "./components/ScrollToTop";
import ProductWrap from "./components/ProductWrap";
import ContactWrap from "./components/ContactWrap";

function App() {
	return (
		<Router>
			<ScrollToTop />
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route exact path='/about' element={<AboutWrapper />} />
				<Route exact path='/projects' element={<ProjectsWrapper />} />
				<Route exact path='/products' element={<ProductWrap />} />
				<Route exact path='/contact_us' element={<ContactWrap />} />
			</Routes>
		</Router>
	);
}
export default App;
