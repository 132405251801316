import React from "react";
import "./HowItWorkCard.css"
const Card = (props) => {
  return (
    <>
      <div className="col-md-4 how-it-work-card">
      <img className="card-image" src={props.image} alt="demo" />
        <h3 className="title">{props.title}</h3>
        <p className="description">{props.detail}</p>
      </div>
    </>
  );
};


export default Card;
