import React from 'react'

const Map = () => (
    <>
        <div className="mapWrap">
            <h3>We are Located here</h3>
            <p>Find us on google map.</p>
            <a href='https://www.google.com/maps/dir//27.6890055,85.3882849/@27.6891021,85.3180791,12z/data=!3m1!4b1'  target="_blank" rel="noopener noreferrer">
            <img src="../images/map.PNG" alt="map" />
            </a>
        </div>
    </>
)

export default Map